import React, {useContext} from 'react'
import AuthContext from '../Context/AuthContext'
import CartContext from '../Context/CartContext'
import DesktopMenu from './DesktopMenu'
import MediaQuery from 'react-responsive'
import MobileLogoCart from './MobileLogoCart'
import MobileMenu from './MobileMenu'


import "./styles.css";
const Header = ({location}) => {
  const {cartCount} = useContext(CartContext)
  const {token, signOut} = useContext(AuthContext)

  return (
    <>
      
      <MediaQuery maxWidth= {600}>
        <MobileMenu
          location={location}
     
          cartCount={cartCount}
          
        />
        <MobileLogoCart />
      </MediaQuery>
      <MediaQuery minWidth= {600}>
        
        <DesktopMenu
          location={location}
          
          cartCount={cartCount}

        />

      </MediaQuery>
  
    </>
  )
}

export default Header
